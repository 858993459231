export default {
  watch: {
    getActiveMusic() {
      setTimeout(() => this.updateMusicListScroll(), 0)
    },
  },
  methods: {
    selectMusic(music) {
      // console.log(music);
      if (music.id == this.getActiveMusicIndex()) {
        this.updatePlayStatus(!this.getPlayingStatus)
      } else {
        this.setActiveMusic(music)
        this.musics = this.hits
        this.updatePlayList(this.hits) // ADICIONAR NA PLAYLIST DO STORE
        this.updatePlayStatus(true)
      }
    },
    checkActiveMusic(index) {
      //console.log( this.getActiveMusicIndex() );
      return index == this.getActiveMusicIndex()
    },
    playAll() {
      const music = this.hits[0] // SELECIONAR PRIMEIRA MÚSICA DA LISTA
      // console.log(this.hits);
      this.musics = this.hits
      this.updatePlayList(this.hits)
      this.setActiveMusic(music)
      this.updatePlayStatus(true)
    },
    updateMusicListScroll(event) {
      console.log(`event:${event}`)
      const parent = this.$refs.musicList
      if (parent == undefined) return
      let childY
      const scroolTopChangeLimit = 250
      const parentY = parent.offsetTop
      let scrollTop = 0
      if (event) {
        childY = event.target.offsetTop
        // console.log('childY:'+ childY);
      } else {
        childY = this.$refs.activeMusic.offsetTop
        // console.log('childY2:'+ childY);
      }
      if (childY > scroolTopChangeLimit) {
        scrollTop = childY - parentY - 100
      }
      parent.scrollTop = scrollTop
    },
  },
}
