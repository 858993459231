<template>

  <b-card-body>
    <div
      class="transaction-item"
    >
      <b-media no-body>
        <b-media-aside>

          <feather-icon
            v-if="!tocando"
            size="30"
            icon="PlayCircleIcon"
            :hit="`hit`+hitId"
            cursor="pointer"
            @click="$emit('handleplayspecify', hitArquivo, `hit`+hitId);hitTocar()"
          />

          <feather-icon
            v-if="tocando"
            size="30"
            icon="PauseCircleIcon"
            :hit="`hit`+hitId"
            cursor="pointer"
            @click="$emit('handlepausespecify');hitPausar()"
          />

        </b-media-aside>
        <b-media-body>
          <h6 class="transaction-title">
            {{ hitNomeComposicao }}
          </h6>
          <small>Testando</small>
          <div class="audio-player" />
        </b-media-body>
      </b-media>
      <div
        class="font-weight-bolder"
      >
        <feather-icon
          size="20"
          icon="StarIcon"
          :hit="`hit`+hitId"
        />
      </div>
    </div>
  </b-card-body>

</template>

<script>
import {
  BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'

import AudioPlayer from '@liripeng/vue-audio-player'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    AudioPlayer,
    // HitPlay,
  },
  props: ['hitId', 'hitArquivo', 'hitNomeComposicao'],
  data() {
    return {

      tocando: false,
      // hits: {},
      // carregamentoApi: false,
      // hitArquivo: '',
      // audioList: [require(`@/assets/audios/musica_1.mp3`)],
      //   tocando: false,
      //   selected_options: [],

    }
  },
  // emits: 'handlePlaySpecify',
  computed: {

    // this.hitsLista();

  },

  methods: {

    handleHitTocando() {
    // this.hitTocando = event;

      console.log('teste')
    },

    hitTocar() {
      // const teste = document.querySelectorAll('[hit="hit193"]');

      this.tocando = true
    },

    hitPausar() {
      // document.querySelector(c)[0];
      this.tocando = false
    },

    // handleBeforePlay('ne'xt) {
    //   //this.title = this.audioList[this.$refs.audioPlayer.currentPlayIndex].name;

    //   next(); // Start play
    // },

    // handlePlaySpecify(arquivo,option) {

    //   //this.hitArquivo = arquivo;
    //   this.audioList = [require(`@/assets/audios/`+arquivo)];

    //   //console.log( option );

    //   //console.log.bind( hit );

    //   this.$nextTick(() => {
    //     this.audioPlayer.play();
    //     this.tocando = true;
    //   });

    // },

    // hitsLista() {

    //   this.carregamentoApi = true;

    //   useJwt.hitLista({
    //     // email: this.usuarioEmail,
    //     // senha: this.usuarioSenha,
    //   })
    //   .then(response => {

    //     //console.log( response.data );

    //     this.hits = response.data.content;

    //     //return this.kb.filter(item => item.title.toLowerCase().includes(knowledgeBaseSearchQueryLower) || item.desc.toLowerCase().includes(knowledgeBaseSearchQueryLower))

    //   })
    //   .catch(error => {

    //     console.log( error );

    //   })
    //   .finally(() => {

    //     this.carregamentoApi = false;

    //   });

    // },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';

.mh-play {
  display: block;
  width: 40px;
  height: 40px;
}

</style>
